<template>
  <div id="HistoriaExitoSec">
    <Header />
    <div class="cont-hiex gradientBK">
      <div class="titulo-content">
        <p class="titulo-hiex">Historias de éxito</p>
      </div>

      <!--MOVIL-->

      <div
        class="body-hiex mobile"
        v-for="(bloq, key) in historiesData2"
        :key="key"
      >
        <div
          class="display-mobile"
          :class="{
            flexiUno:
              bloq.caja[0].tipo == 'info' && bloq.caja[1].tipo == 'imagen',
          }"
        >
          <div class="caja1">
            <div v-if="bloq.caja[0].tipo == 'info'" class="info-cont">
              <p class="titulo">
                <strong>{{ bloq.caja[0].titulo }}</strong>
              </p>
              <p v-html="bloq.caja[0].texto" class="texto-p"></p>
              <div class="dflx sm-cont">
                <!--<a :href="bloq.caja[0].facebook"
                  ><img src="../assets/fb-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[0].instagram"
                  ><img src="../assets/ig-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[0].twitter"
                  ><img src="../assets/tw-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[0].sitioweb"
                  ><img src="../assets/web-black.svg" alt="sm" class="sm-logo" 
                /></a>--->
                <a :href="bloq.caja[0].instagram" v-if="bloq.caja[0].instagram !== ''" target="_blank" rel="noopener noreferrer">
                  <!--<img src="../assets/ig-black.svg" alt="sm" class="sm-logo"/>-->
                  <p class="seemore">Ver publicación</p>
                </a>
              </div>
            </div>

            <img
              v-if="bloq.caja[0].tipo == 'imagen'"
              :src="srcImg(bloq.caja[0].imagen)"
              alt="imagen bloque"
            />
          </div>
          <div class="caja2">
            <div v-if="bloq.caja[1].tipo == 'info'" class="info-cont">
              <p class="titulo">
                <strong>{{ bloq.caja[1].titulo }}</strong>
              </p>
              <p v-html="bloq.caja[1].texto" class="texto-p"></p>
              <div class="dflx sm-cont">
                <!---<a :href="bloq.caja[1].facebook"
                  ><img src="../assets/fb-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[1].instagram"
                  ><img src="../assets/ig-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[1].twitter"
                  ><img src="../assets/tw-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[1].sitioweb"
                  ><img src="../assets/web-black.svg" alt="sm" class="sm-logo"
                /></a>-->
                <a :href="bloq.caja[1].instagram" v-if="bloq.caja[1].instagram !== ''" target="_blank" rel="noopener noreferrer">
                  <!--<img src="../assets/ig-black.svg" alt="sm" class="sm-logo"/>-->
                  <p class="seemore">Ver publicación</p>
                </a>
              </div>
            </div>

            <img
              v-if="bloq.caja[1].tipo == 'imagen'"
              :src="srcImg(bloq.caja[1].imagen)"
              alt="imagen bloque"
            />
          </div>
        </div>
      </div>

      <!--PC-->
      <div
        class="body-hiex pc"
        v-for="(bloq, key) in historiesData[0].body" :key="key"
      >
        <div
          v-if="bloq.tipo == 'caja'"
          class="dflx jcont"
          :class="{
            imageFlex:
              bloq.caja[0].tipo == 'imagen' && bloq.caja[1].tipo == 'imagen',
            infoFlex:
              bloq.caja[0].tipo == 'info' && bloq.caja[1].tipo == 'info',
          }"
        >
          <div
            class="caja1"
          >
            <div v-if="bloq.caja[0].tipo == 'info'" class="info-cont"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1500"
            :data-aos-offset="100 + (key+1) * 120"
            :data-aos-delay="500 + (key+1) * 90">
              <p class="titulo">
                <strong>{{ bloq.caja[0].titulo }}</strong>
              </p>
              <p v-html="bloq.caja[0].texto" class="texto-p"></p>
              <div class="dflx sm-cont">
                <!--<a :href="bloq.caja[0].facebook" v-if="bloq.caja[0].facebook.length > 0"
                  ><img src="../assets/fb-black.svg" alt="sm" class="sm-logo"
                /></a>-->
                <a :href="bloq.caja[0].instagram" v-if="bloq.caja[0].instagram !== ''" target="_blank" rel="noopener noreferrer">
                  <!--<img src="../assets/ig-black.svg" alt="sm" class="sm-logo"/>-->
                  <p class="seemore">Ver publicación</p>
                </a>
                <!--<a :href="bloq.caja[0].twitter" v-if="bloq.caja[0].twitter.length > 0"
                  ><img src="../assets/tw-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[0].sitioweb" v-if="bloq.caja[0].sitioweb.length > 0"
                  ><img src="../assets/web-black.svg" alt="sm" class="sm-logo"
                /></a>--->
              </div>
            </div>

            <img
              v-if="bloq.caja[0].tipo == 'imagen'"
              :src="srcImg(bloq.caja[0].imagen)"
              alt="imagen bloque"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1500"
            :data-aos-offset="100 + (key+1) * 100"
            :data-aos-delay="500 + (key+1) * 90"
            />
          </div>
          <div
            class="caja2"
          >
            <div v-if="bloq.caja[1].tipo == 'info'" class="info-cont"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1500"
            :data-aos-offset="100 + (key+1) * 120"
            :data-aos-delay="500 + (key+1) * 90">
              <p class="titulo">
                <strong>{{ bloq.caja[1].titulo }}</strong>
              </p>
              <p v-html="bloq.caja[1].texto" class="texto-p"></p>
              <div class="dflx sm-cont">
                <!--<a :href="bloq.caja[1].facebook" v-if="bloq.caja[1].facebook.length > 0"
                  ><img src="../assets/fb-black.svg" alt="sm" class="sm-logo"
                /></a>--->
                <a :href="bloq.caja[1].instagram" v-if="bloq.caja[1].instagram !== ''" target="_blank" rel="noopener noreferrer">
                  <!--<img src="../assets/ig-black.svg" alt="sm" class="sm-logo"/>-->
                  <p class="seemore">Ver publicación</p>
                </a>
                <!---<a :href="bloq.caja[1].twitter" v-if="bloq.caja[1].twitter.length > 0"
                  ><img src="../assets/tw-black.svg" alt="sm" class="sm-logo"
                /></a>
                <a :href="bloq.caja[1].sitioweb" v-if="bloq.caja[1].sitioweb.length > 0"
                  ><img src="../assets/web-black.svg" alt="sm" class="sm-logo"
                /></a>--->
              </div>
            </div>

            <img
              v-if="bloq.caja[1].tipo == 'imagen'"
              :src="srcImg(bloq.caja[1].imagen)"
              alt="imagen bloque"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1500"
            :data-aos-offset="100 + (key+1) * 100"
            :data-aos-delay="500 + (key+1) * 90"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from "../components/on-site/Header";
import Footer from "../components/on-site/Footer.vue";
let urlpath = require("../global/url");
export default {
  data() {
    return {
      urlpath: urlpath,
      dataMovil: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.getAllInfoHts2("historias");
  },
  computed: {
    historiesData() {
      return this.$store.getters["historias2/data"];
    },

    historiesData2() {
      let data = this.$store.getters["historias2/data"];
      let array = [];
      let body = data;
      for (let index = 0; index < body.length; index++) {
        let arr = body[index].body;
        for (let j = 0; j < arr.length; j++) {
          if (
            arr[j].caja[0].tipo == "imagen" &&
            arr[j].caja[1].tipo == "info"
          ) {
            array.push(arr[j]);
          } else if (
            arr[j].caja[0].tipo == "info" &&
            arr[j].caja[1].tipo == "imagen"
          ) {
            array.push(arr[j]);
          } 
          /***/else if (
            arr[j].caja[0].tipo == "imagen" &&
            arr[j].caja[1].tipo == "imagen"
          ) {
            let cajacero = arr[j].caja[0];
            let cajacerodos = arr[j + 1].caja[0];
            let caja = [];
            caja.push(cajacero);
            caja.push(cajacerodos);
            let modelo = { tipo: "caja", caja };
            array.push(modelo);

            let cajacero1 = arr[j].caja[1];
            let cajacerodos1 = arr[j + 1].caja[1];
            console.log(cajacero1)
            let caja1 = [];
            caja1.push(cajacero1);
            caja1.push(cajacerodos1);
            let modelo1 = { tipo: "caja", caja: caja1 };
            array.push(modelo1);
          } 
        }
      }

      this.dataMovil = array;
      console.log(this.dataMovil);
      return array;
    },
  },
  methods: {
    ...mapActions("historias2", ["getAllInfoHts2"]),

    getAllInfoHts2: async function () {
      let result = await this.$store.dispatch("historias2/getAllInfoHts2", {
        option: "historias",
      });
      //console.log(result) 
      this.info = result;
      return result;
    },

    srcImg: function (img) {
      let src = `${urlpath.url()}/historias-img/${img}`;

      //this.status_img(img)

      return src;
    },
    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("gradientBK")[1];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.changeBackgroundPartners);
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Historias de éxito - Legrafica';
            }
        },
  }
};
</script>
<style>
p {
  margin: 0;
}
.cont-hiex {
  background-color: #e4e4f9;
  background-image: url("../assets/recurso2.png");
  background-repeat: no-repeat;
  background-size: 64.063vw;
  background-position-x: right;
  background-position-y: top;
  padding-top: 16.563vw;
  padding-bottom: 5.052vw;
}
.titulo-content,
.body-hiex {
  width: 81.667vw;
  margin: 0 auto 0;
  padding-bottom: 5.052vw;
}
.body-hiex.pc {
  display: block;
}
.body-hiex.mobile {
  display: none;
}
.titulo-hiex {
  font-family: "Gramatika-Medium";
  font-size: 7.813vw;
  line-height: 121.3%;
  color: #ef67c2;
  margin: 0 auto 0 0;
  width: 35.99vw;
}
.cont-hiex img {
  width: 39.583vw;
  height: auto;
}
.dflx {
  display: flex;
  align-items: center;
}
.imageFlex {
  align-items: flex-start;
}
.infoFlex .caja1 .texto-p {
  width: 24.375vw;
}
.infoFlex .caja2 .texto-p {
  width: 30.208vw;
}
.info-cont {
  padding: 0.99vw 2.292vw;
  background-image: url("../assets/guion.svg");
  background-size: 2.292vw;
  background-repeat: no-repeat;
    width: fit-content;
    height: fit-content;
}
.info-cont p strong, .seemore {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  font-size: 1.042vw;
  line-height: 179.8%;
  color: #ef67c2;
  padding-bottom: 5vw;
}
.seemore {
    text-decoration: underline;
}
img.sm-logo {
  width: auto;
  height: 0.885vw;
  transition: .5s;
  opacity: 1;
}
img.sm-logo:hover{
  opacity: .5;
}
.dflx.sm-cont {
  margin-top: 5.104vw;
  /*width: 7.865vw;*/
  width: 10.865vw;
  justify-content: space-between;
}
p.titulo {
  margin-bottom: 4.948vw;
}
p.texto-p {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 179.8%;
  color: #000000;
  width: 21.823vw;
}
p.texto-p span {
  color: #000000 !important;
}
.jcont {
  justify-content: space-between;
}
.caja1 .info-cont {
  margin-left: 2.448vw;
}
.caja2 .info-cont {
  margin-right: 2.448vw;
}

@media (max-width: 768px) {
  .body-hiex.pc {
    display: none;
  }
  .body-hiex.mobile {
    display: block;
  }
  .display-mobile {
    display: flex;
    flex-direction: column;
  }
  .flexiUno {
    flex-direction: column-reverse;
  }
  .cont-hiex {
    background-image: none;
    padding-top: 41.787vw;
    padding-bottom: 41.787vw;
  }
  .cont-hiex img {
    width: 83.333vw;
    height: auto;
  }
  .titulo-hiex {
    width: 64.99vw;
    font-size: 14.493vw;
    line-height: 121.3%;
  }
  .titulo-content {
    padding-bottom: 14.696vw;
  }
  img.sm-logo {
    width: auto;
    height: 0.885vw;
  }
  .info-cont {
    background-size: 10.628vw;
    margin: 16.184vw 6.763vw;
    padding: 5.797vw 0 5.797vw 12.56vw;
  }
  .info-cont p strong, .seemore {
    font-size: 3.382vw;
    line-height: 179.8%;
  }
  p.texto-p {
    font-size: 2.1739130434782608VW;
    line-height: 3.908212560386473VW;
    width: 81%;
    color: #000000 !important;
  }
  .dflx.sm-cont {
    margin-top: 14.251vw;
    /*width: 28.019vw;*/
    width: 34.019vw;
    justify-content: space-between;
  }
  img.sm-logo {
    width: auto;
    height: 3.14vw;
  }
}
</style>