var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"HistoriaExitoSec"}},[_c('Header'),_c('div',{staticClass:"cont-hiex gradientBK"},[_vm._m(0),_vm._l((_vm.historiesData2),function(bloq,key){return _c('div',{key:key,staticClass:"body-hiex mobile"},[_c('div',{staticClass:"display-mobile",class:{
          flexiUno:
            bloq.caja[0].tipo == 'info' && bloq.caja[1].tipo == 'imagen',
        }},[_c('div',{staticClass:"caja1"},[(bloq.caja[0].tipo == 'info')?_c('div',{staticClass:"info-cont"},[_c('p',{staticClass:"titulo"},[_c('strong',[_vm._v(_vm._s(bloq.caja[0].titulo))])]),_c('p',{staticClass:"texto-p",domProps:{"innerHTML":_vm._s(bloq.caja[0].texto)}}),_c('div',{staticClass:"dflx sm-cont"},[(bloq.caja[0].instagram !== '')?_c('a',{attrs:{"href":bloq.caja[0].instagram,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"seemore"},[_vm._v("Ver publicación")])]):_vm._e()])]):_vm._e(),(bloq.caja[0].tipo == 'imagen')?_c('img',{attrs:{"src":_vm.srcImg(bloq.caja[0].imagen),"alt":"imagen bloque"}}):_vm._e()]),_c('div',{staticClass:"caja2"},[(bloq.caja[1].tipo == 'info')?_c('div',{staticClass:"info-cont"},[_c('p',{staticClass:"titulo"},[_c('strong',[_vm._v(_vm._s(bloq.caja[1].titulo))])]),_c('p',{staticClass:"texto-p",domProps:{"innerHTML":_vm._s(bloq.caja[1].texto)}}),_c('div',{staticClass:"dflx sm-cont"},[(bloq.caja[1].instagram !== '')?_c('a',{attrs:{"href":bloq.caja[1].instagram,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"seemore"},[_vm._v("Ver publicación")])]):_vm._e()])]):_vm._e(),(bloq.caja[1].tipo == 'imagen')?_c('img',{attrs:{"src":_vm.srcImg(bloq.caja[1].imagen),"alt":"imagen bloque"}}):_vm._e()])])])}),_vm._l((_vm.historiesData[0].body),function(bloq,key){return _c('div',{key:key,staticClass:"body-hiex pc"},[(bloq.tipo == 'caja')?_c('div',{staticClass:"dflx jcont",class:{
          imageFlex:
            bloq.caja[0].tipo == 'imagen' && bloq.caja[1].tipo == 'imagen',
          infoFlex:
            bloq.caja[0].tipo == 'info' && bloq.caja[1].tipo == 'info',
        }},[_c('div',{staticClass:"caja1"},[(bloq.caja[0].tipo == 'info')?_c('div',{staticClass:"info-cont",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"1500","data-aos-offset":100 + (key+1) * 120,"data-aos-delay":500 + (key+1) * 90}},[_c('p',{staticClass:"titulo"},[_c('strong',[_vm._v(_vm._s(bloq.caja[0].titulo))])]),_c('p',{staticClass:"texto-p",domProps:{"innerHTML":_vm._s(bloq.caja[0].texto)}}),_c('div',{staticClass:"dflx sm-cont"},[(bloq.caja[0].instagram !== '')?_c('a',{attrs:{"href":bloq.caja[0].instagram,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"seemore"},[_vm._v("Ver publicación")])]):_vm._e()])]):_vm._e(),(bloq.caja[0].tipo == 'imagen')?_c('img',{attrs:{"src":_vm.srcImg(bloq.caja[0].imagen),"alt":"imagen bloque","data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"1500","data-aos-offset":100 + (key+1) * 100,"data-aos-delay":500 + (key+1) * 90}}):_vm._e()]),_c('div',{staticClass:"caja2"},[(bloq.caja[1].tipo == 'info')?_c('div',{staticClass:"info-cont",attrs:{"data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"1500","data-aos-offset":100 + (key+1) * 120,"data-aos-delay":500 + (key+1) * 90}},[_c('p',{staticClass:"titulo"},[_c('strong',[_vm._v(_vm._s(bloq.caja[1].titulo))])]),_c('p',{staticClass:"texto-p",domProps:{"innerHTML":_vm._s(bloq.caja[1].texto)}}),_c('div',{staticClass:"dflx sm-cont"},[(bloq.caja[1].instagram !== '')?_c('a',{attrs:{"href":bloq.caja[1].instagram,"target":"_blank","rel":"noopener noreferrer"}},[_c('p',{staticClass:"seemore"},[_vm._v("Ver publicación")])]):_vm._e()])]):_vm._e(),(bloq.caja[1].tipo == 'imagen')?_c('img',{attrs:{"src":_vm.srcImg(bloq.caja[1].imagen),"alt":"imagen bloque","data-aos":"fade-zoom-in","data-aos-easing":"ease-in-back","data-aos-duration":"1500","data-aos-offset":100 + (key+1) * 100,"data-aos-delay":500 + (key+1) * 90}}):_vm._e()])]):_vm._e()])})],2),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titulo-content"},[_c('p',{staticClass:"titulo-hiex"},[_vm._v("Historias de éxito")])])
}]

export { render, staticRenderFns }